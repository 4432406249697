/* Primary Color: #80CED7
Secondary Color: #E5D9F2 */

* {
    font-family: sans-serif;
}

body {
    background: #f3ebfb;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.gradient-card {
    background-size: 200%;
    transition: 0.6s !important;
    background-image: linear-gradient(45deg, #E5D9F2, #80CED7);
    margin: 10px;
}

.gradient-card:hover {
    background-position: right;
}